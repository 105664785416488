<template>
  <div class="fs-14 teamServer" style="overflow-y: auto; width: 100%; height: 100%;box-sizing: border-box;">
    <div style="background: #16a37f;padding:10px;color:#fff;">
      <span style="display: block;width:10%;display: block;float: left;" @click="$router.go(-1)">返回</span>
      <span style="text-align: center;width: 90%;display: block;font-size: 0.16rem;">苹果快捷方式教程</span>
    </div>
    <div style="padding:0 20px;">
      <h2>1、右上角选择“更多”按钮</h2>
      <img style="width: 100%;" src="./images/shortcut_ios1.png">
      <h2>2、选择“复制链接”</h2>
      <img style="width: 100%;" src="./images/shortcut_ios2.png">
      <h2>3、打开“Safari浏览器”APP，在搜索框中粘贴链接，选择“粘贴并前往”</h2>
      <img style="width: 10%;" src="./images/shortcut_ios3.png">
      <img style="width: 100%;" src="./images/shortcut_ios4.png">
      <h2>4、页面打开后，在底部选择“分享”按钮</h2>
      <img style="width: 100%;" src="./images/shortcut_ios5.png">
      <h2>5、选择“添加到主屏幕”，完成操作</h2>
      <img style="width: 100%;" src="./images/shortcut_ios6.png">
    </div>


  </div>
</template>
<style>
@media only screen and (min-width: 800px) {
  .teamServer{
    width: 740px!important;
    margin: 0 auto;
  }
}
</style>